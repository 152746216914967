import React from "react";
import { Layout } from "../../components";
import { SvgArrow } from "../../components";

const About = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "About Us",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/o-nas/",
      }}
    >
      <div className="agency agency_container">
        <section className="agency_header">
          <h1>About Us</h1>
          <p>Your external marketing department</p>
        </section>
        <section className="agency_description">
          <p>
            We are focused on achieving our Clients’ goals, which is why we
            focus on clear communication, recommend solutions, and execute the
            implementation of assumptions. We undertake cooperation based on
            mutual trust that we are the experts you can actually rely on. We
            become your own, external, marketing department. Our Agency consists
            of companies from key marketing areas. Thanks to the common
            ownership structure (ADream is a co-owner of all companies), we are
            constantly developing and raising each other's competences (we are
            together not only as an entry in the National Court Register but
            also in one office in the center of Krakow, Poland).
          </p>
        </section>
        <section className="agency_grid">
          <a
            className="agency_grid__item"
            href="https://zensite.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../../assets/img/agency/zensite.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">Zensite</h3>
              <p className="agency_grid__item-spec">
                www / landing page / wordpress / react.js
              </p>
              <p className="agency_grid__item-description">
                We create effective and charming websites. Ease of use, modern
                and beautiful design, speed, and realization of Clients’ goals
                are our basic rules whenever we are creating a new site. We
                design and code both landing pages, local companies websites,
                eStores, as well as big corporate web portals
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="https://studioleon.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../../assets/img/agency/studio_leon.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">Studio Leon</h3>
              <p className="agency_grid__item-spec">branding agency</p>
              <p className="agency_grid__item-description">
                We specialize in designing of printing materials such as:
                packagings, labels, and creating coherent advertising materials
                which strengthen the company's image or support sales. We
                provide you with full support in choosing the right printing
                house and managing the production processes.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="https://when.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../../assets/img/agency/when.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">When</h3>
              <p className="agency_grid__item-spec">Internet marketing</p>
              <p className="agency_grid__item-description">
                We are a digital marketing agency offering a wide scope of
                services dealing with Internet marketing such as: Google Ads
                (ADWords), Facebook Ads, Social Media, LinkedIn Ads, Internet
                analytics. We have the highest ranked status as a Google Premier
                Partner and we participate in the biggest Google events on a
                constant basis as well.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="https://flyemotion.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../../assets/img/agency/flyemotion.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">Flyemotion</h3>
              <p className="agency_grid__item-spec">motion</p>
              <p className="agency_grid__item-description">
                drone aerial filming and photography
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="https://forsecure.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../../assets/img/agency/forsecure.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">ForSecure</h3>
              <p className="agency_grid__item-spec">
                secured website / website management / regular updates
              </p>
              <p className="agency_grid__item-description">
                ForSecure is a subscription based comprehensive package of
                services, within which we secure, update, and provide regular
                servicing of Internet websites for our Clients, and support them
                with technical knowledge whenever necessary. Let us take care of
                your website, while you can fully focus on growing your
                business!
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
          <a
            className="agency_grid__item"
            href="http://morestore.pl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div className="agency_grid__item-logo">
                <img
                  src={require("../../assets/img/agency/morestore.svg")}
                  alt="Zensite"
                />
              </div>
              <h3 className="agency_grid__item-name">Morestore</h3>
              <p className="agency_grid__item-spec">e-commerce</p>
              <p className="agency_grid__item-description">
                We specialize in creating and coding effective online shops,
                based both on open-source as well as dedicated solutions.
              </p>
            </div>
            <div className="agency_grid__item-button">
              <SvgArrow />
            </div>
          </a>
        </section>
      </div>
    </Layout>
  );
};
export default About;
